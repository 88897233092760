<template>
    <div class="vendor-page my-5">
        <WebLoader v-if="loading" />
        <b-container>
            <b-row>
                <b-col>
                    <b-card>
                        <b-card-header>
                            <b-row>
                                <b-col>
                                    <h4>Payment Details</h4>
                                </b-col>

                            </b-row>
                        </b-card-header>

                        <div class="filter-controls py-2">
                            <b-form-group>
                                <b-form-input type="date" v-model="startDate"></b-form-input>
                            </b-form-group>
                            <b-form-group>
                                <b-form-input type="date" v-model="endDate"></b-form-input>
                            </b-form-group>
                            <b-form-group>
                                <b-form-input placeholder="Enter JazzCash Reference Number"
                                    v-model="jazzcashRefNumber"></b-form-input>
                            </b-form-group>
                            <b-button @click="fetchPayments">Filter</b-button>
                        </div>
                        <div class="table-responsive">
                            <b-table :items="formattedPayments" :fields="paymentFields" class="mt-3" head-variant="dark"
                                responsive hover small show-empty></b-table>
                        </div>
                        <!-- <b-alert v-if="errorMessage" variant="danger" class="mt-3">{{ errorMessage }}</b-alert> -->




                    </b-card>
                    <!-- Display Total Income -->

                </b-col>
                <div class="total-income my-3">
                    <b>💰 Total Profit: </b> <span>{{ totalIncome.toFixed(2) }}</span>
                </div>
            </b-row>

        </b-container>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/../config"; // Adjust the import based on your project structure

export default {
    data() {
        return {
            startDate: '',
            endDate: '',
            jazzcashRefNumber: '',
            payments: [],
            formattedPayments: [],
            errorMessage: '',
            loading: false,
            paymentFields: [
                { key: "booking_id", label: "Booking ID", sortable: true },
                { key: "customer_id", label: "Customer ID", sortable: true },
                { key: "customer_name", label: "Customer Name", sortable: true },
                { key: "club_name", label: "Club Name", sortable: true },
                { key: "turf_name", label: "Turf Name", sortable: true },
                { key: "date", label: "Booking Date", sortable: true },
                { key: "time_slot", label: "Time Slot", sortable: true },
                { key: "vendor_payment_type", label: "Vendor Payment Type", sortable: true },
                { key: "admin_percentage", label: "Admin Percentage", sortable: true },
                { key: "base_amount", label: "Base Amount", sortable: true },
                { key: "total_amount", label: "Total Amount", sortable: true },
                { key: "amount_taken_from_customer", label: "Amount Taken From Customer", sortable: true },
                { key: "remaining_amount", label: "Remaining Amount", sortable: true },
                { key: "jazzcash_refno", label: "JazzCash Ref No", sortable: true },
                { key: "payment_status", label: "Payment Status", sortable: true },

                { key: "created_at", label: "Created At", sortable: true },

            ],
        };
    },
    computed: {
        totalIncome() {
            return this.formattedPayments.reduce((sum, payment) => {
                // Parse `admin_percentage` to a number and add to the sum
                const adminPercentage = parseFloat(payment.admin_percentage);
                return sum + (isNaN(adminPercentage) ? 0 : adminPercentage);
            }, 0);
        },
    },

    mounted() {
        this.fetchPayments(); // Call fetchPayments on component mount
    },
    methods: {
        async fetchPayments() {
            this.errorMessage = ''; // Reset error message
            this.loading = true; // Set loading state to true

            // Validate that both start date and end date are provided
            if ((this.startDate && !this.endDate) || (!this.startDate && this.endDate)) {
                this.$showErrorMessageToast("Both start date and end date must be provided.");
                this.loading = false; // Reset loading state
                return;
            }

            // Add query parameters
            const params = {};
            if (this.startDate) params.start_date = this.startDate;
            if (this.endDate) params.end_date = this.endDate;
            if (this.jazzcashRefNumber) params.jazzcash_ref_number = this.jazzcashRefNumber;

            try {
                const response = await axios.get(`${BASE_URL}/fetch-payments`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    params, // Only pass params if any are set
                });

                if (response.data.success) {
                    this.payments = response.data.data; // Store the raw payment data
                    this.formatPayments(); // Format the payments for display
                } else {
                    this.errorMessage = "No payments found for the given criteria.";
                    this.payments = [];
                    this.formattedPayments = [];
                }
            } catch (error) {
                this.$showErrorMessageToast("Error fetching payments:", error);
                this.errorMessage = "An error occurred while fetching payments.";
            } finally {
                this.loading = false; // Reset loading state
            }
        },
        formatPayments() {
            this.formattedPayments = this.payments.flatMap(payment => {
                return payment.booking_details.map(bookingDetail => {
                    const timeSlots = bookingDetail.timeslots;

                    // Format time slots into a range
                    const formattedTimeSlot = this.formatTimeSlots(timeSlots);

                    return {
                        booking_id: payment.booking_id,
                        customer_id: payment.customer_id,
                        vendor_payment_type: payment.vendor_payment_type,
                        admin_percentage: payment.admin_percentage,
                        base_amount: payment.base_amount,
                        total_amount: payment.total_amount,
                        amount_taken_from_customer: payment.amount_taken_from_customer,
                        remaining_amount: payment.remaining_amount,
                        jazzcash_refno: payment.jazzcash_refno,
                        payment_status: payment.payment_status,
                        date: bookingDetail.date,
                        turf_name: bookingDetail.turf_name,
                        club_name: bookingDetail.club_name,
                        customer_name: bookingDetail.customer_name,
                        created_at: bookingDetail.created_at,
                        time_slot: formattedTimeSlot,
                    };
                });
            });
        },
        formatTimeSlots(timeSlots) {
            if (!timeSlots.length) return '';

            const sortedSlots = timeSlots.map(slot => {
                const [start, end] = slot.time_slot.split('-');
                return { start, end };
            });

            let ranges = [];
            let currentRange = { start: sortedSlots[0].start, end: sortedSlots[0].end };

            for (let i = 1; i < sortedSlots.length; i++) {
                if (sortedSlots[i].start === currentRange.end) {
                    currentRange.end = sortedSlots[i].end; // Extend the current range
                } else {
                    ranges.push(currentRange); // Push the completed range
                    currentRange = { start: sortedSlots[i].start, end: sortedSlots[i].end }; // Start a new range
                }
            }
            ranges.push(currentRange); // Push the last range

            return ranges.map(range => `${range.start}-${range.end}`).join(', '); // Format the ranges
        },
    },
};
</script>

<style scoped>
/* .payment-page {
    padding: 20px;
} */

.filter-controls {
    display: flex;
    justify-content: end;
    gap: 1rem;
    padding-inline: 1rem;
}

.card-body {
    padding: 0;
}

.table-responsive {
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    margin-bottom: 0;
    max-height: 500px;
}

.table-responsive::-webkit-scrollbar {
    width: 10px;
    /* Width of vertical scrollbar */
    height: 10px;
    /* Height of horizontal scrollbar */
}

/* Track - background of the scrollbar */
.table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle (thumb) */
.table-responsive::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid transparent;
}

/* Handle (thumb) on hover */
.table-responsive::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* Firefox */
.table-responsive {
    scrollbar-width: thin;
    /* Set the scrollbar width */
    scrollbar-color: #888 #f1f1f1;
    /* Scrollbar thumb and track color */
}

.table-responsive::-webkit-scrollbar-button {
    display: none;
}

.total-income {
    text-align: right;
    font-size: large;
}

::v-deep td {
    vertical-align: middle;
    font-size: 14px;
}

h4 {
    margin: 0 !important;
}
</style>
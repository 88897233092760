import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ContactView from "../views/ContactView.vue";
import PaymentView from "../views/PaymentView.vue";
import BookingView from "../views/BookingView.vue";
import Login from "@/components/FormLogin";
import UserRegister from "@/components/Customer/UserRegister";
import ForgotPassword from "@/components/ForgotPassword";
import ChangePassword from "@/components/ChangePassword";
import OtpScreen from "@/components/OtpPage";
import VendorRegister from "@/components/Vendor/VendorRegister";
import UserProfile from "@/components/UserProfile";
import ClubPage from "@/components/Customer/ClubPage";
import TurfPage from "@/components/Customer/TurfPage";
import BookingPayment from "@/components/Customer/BookingPayment";
import AddTurf from "@/components/Vendor/AddTurf";
import UpdateTurf from "@/components/Vendor/UpdateTurf";
import CustomerPage from "@/components/Admin/CustomerPage.vue";
import VendorPage from "@/components/Admin/VendorPage.vue";
import PaymentPage from "@/components/Admin/PaymentPage.vue";
import ComingSoon from "@/components/coming-soon.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/payment",
    name: "payment",
    component: PaymentView,
  },
  {
    path: "/booking",
    name: "Booking",
    component: BookingView,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    name: "register",
    path: "/register",
    component: UserRegister,
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    name: "otp",
    path: "/otp",
    component: OtpScreen,
  },
  {
    name: "change-password",
    path: "/change-password",
    component: ChangePassword,
  },
  {
    name: "vendor-register",
    path: "/vendor-register",
    component: VendorRegister,
  },
  {
    name: "my-profile",
    path: "/my-profile",
    component: UserProfile,
  },
  {
    name: "club",
    path: "/club",
    component: ClubPage,
    meta: { requiresFetch: true },
  },
  {
    name: "turf",
    path: "/turf",
    component: TurfPage,
    meta: { requiresFetch: false },
  },
  {
    name: "Booking Payment",
    path: "/booking-payment",
    component: BookingPayment,
  },
  {
    name: "add-turf",
    path: "/add-turf",
    component: AddTurf,
  },
  {
    name: "update-turf",
    path: "/update-turf",
    component: UpdateTurf,
  },
  {
    name: "customer",
    path: "/customer",
    component: CustomerPage,
  },
  {
    name: "vendor",
    path: "/vendor",
    component: VendorPage,
  },
  {
    name: "admin-payment",
    path: "/admin-payment",
    component: PaymentPage,
  },
  {
    name: "coming-soon",
    path: "/coming-soon",
    component: ComingSoon,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.afterEach((to, from) => {
  to.meta.previousRoute = from.name;
});

export default router;

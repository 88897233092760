<template>
  <div>
    <WebLoader v-if="loading" />
    <b-container v-if="turfs.length" id="mainContainer" align-v="center">
      <b-row align-v="center" align-h="center">
        <b-col md="12" class="mt-4 mb-4">
          <div>
            <b-tabs v-model="activeTab" pills>
              <b-tab v-for="turf in turfs" :key="turf.id" :title="turf.turf_name">
                <b-card id="cardLogin" class="scale-in-bl">
                  <b-form @submit.prevent="onSubmit(turf.id)" @reset="onReset" v-if="show" id="formLogin">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="blob">
                      <path fill="#00853e"
                        d="M66.2,-49.1C81.5,-33.5,86.8,-6.2,79.8,15.6C72.7,37.5,53.3,53.9,30.9,65.1C8.5,76.2,-16.9,81.9,-36.2,73.3C-55.5,64.7,-68.8,41.7,-70.8,19.9C-72.8,-2,-63.6,-22.7,-49.9,-37.8C-36.2,-53,-18.1,-62.7,3.6,-65.6C25.4,-68.5,50.8,-64.6,66.2,-49.1Z"
                        transform="translate(100 100)" />
                    </svg>

                    <h1 class="text-center mb-2">
                      Update {{ turf.turf_name }}
                    </h1>
                    <span class="text-center mb-4 other-account">Update your turf by changing the details below:</span>
                    <b-row align-v="center" align-h="center">
                      <b-col md="6">
                        <InputField id="turf_name" v-model="turf.turf_name" type="text" placeholder="Name of Turf"
                          :isDisabled="!turf.editing">
                        </InputField>
                      </b-col>

                      <b-col md="6">
                        <InputField id="turf_size" v-model="turf.turf_size" type="text" placeholder="Size of Turf"
                          :isDisabled="!turf.editing">
                        </InputField>
                      </b-col>

                      <b-col class="position-relative" md="6">
                        <b-form-group class="pb-3" id="input-group-1" label-for="turf_status">
                          <b-form-select v-model="turf.turf_status" :options="statusOptions" class="option-setting"
                            style="position: relative" :disabled="!turf.editing"></b-form-select>
                          <div v-if="turf.turf_status === 'inactive'" class=""></div>

                          <div v-if="turf.turf_status === 'inactive'" class="circle-red"></div>

                          <div v-if="turf.turf_status === 'active'" class="circle-green"></div>
                        </b-form-group>
                        <div v-if="turf.status === 'inactive'" class="circle-red"></div>
                        <div v-if="turf.status === 'active'" class="circle-green"></div>
                      </b-col>

                      <b-col md="6" class="turf-image-upload">
                        <b-form-group class="pb-3 position-relative" id="input-group-3" label-for="turfImage">
                          <b-form-file id="turfImage" class="input" accept=".jpg, .png, .jpeg" v-model="turf.turf_image"
                            placeholder="Upload/Update Image" required :disabled="!turf.editing"
                            @change="updateTurfImage($event, turf)">
                          </b-form-file>
                        </b-form-group>
                        <div class="image-name position-absolute">
                          <small>
                            {{ extractFileName(turf.turf_image) }}
                          </small>
                        </div>
                      </b-col>

                      <b-row>
                        <b-col md="12">
                          <h2 class="text-center mb-2">Peak Days Details</h2>
                        </b-col>
                      </b-row>

                      <b-col md="12">
                        <multiselect class="pb-3" v-model="turf.selectedPeakDays" :options="days" :multiple="true"
                          :taggable="true" @tag="addTag" placeholder="Select one or more peak days" label="text"
                          track-by="value" :disabled="!turf.editing">
                        </multiselect>
                      </b-col>

                      <b-col md="4">
                        <timePicker label="Peak Day Peak Start Time" label-for="peak-day-peak-start-time"
                          v-model="turf.peak_day_peak_start_time" :isDisabled="!turf.editing">
                        </timePicker>
                      </b-col>

                      <b-col md="4">
                        <timePicker label="Peak Day Peak End Time" label-for="peak-day-peak-end-time"
                          v-model="turf.peak_day_peak_end_time" :isDisabled="!turf.editing">
                        </timePicker>
                      </b-col>

                      <b-col md="4">
                        <timePicker label="Peak Day Off Peak Start Time" label-for="peak-day-off-peak-start-time"
                          v-model="turf.peak_day_off_peak_start_time" :isDisabled="!turf.editing">
                        </timePicker>
                      </b-col>

                      <b-col md="4">
                        <timePicker label="Peak Day Off Peak End Time" label-for="peak-day-off-peak-end-time"
                          v-model="turf.peak_day_off_peak_end_time" :isDisabled="!turf.editing">
                        </timePicker>
                      </b-col>

                      <b-col md="4">
                        <InputField id="peak_day_peak_hours_charges" v-model="turf.peak_day_peak_hours_charges"
                          type="text" placeholder="Peak Day Peak Hours Charges" :isDisabled="!turf.editing">
                        </InputField>
                      </b-col>

                      <b-col md="4">
                        <InputField id="peak_day_peak_hours_charges" v-model="turf.peak_day_off_peak_hours_charges"
                          type="text" placeholder="Peak Day Peak Hours Charges" :isDisabled="!turf.editing">
                        </InputField>
                      </b-col>

                      <b-row>
                        <b-col md="12">
                          <h2 class="text-center mb-2">Off-Peak Days Details</h2>
                        </b-col>
                      </b-row>

                      <b-col md="12">
                        <multiselect class="pb-3" v-model="turf.selectedoffPeakDays" :options="days" :multiple="true"
                          :taggable="true" @tag="addTag" placeholder="Select one or more off peak days" label="text"
                          track-by="value" :disabled="!turf.editing">
                        </multiselect>
                      </b-col>

                      <b-col md="4">
                        <timePicker label="Off-Peak Day Peak Start Time" label-for="off-peak-day-peak-start-time"
                          v-model="turf.off_peak_day_peak_start_time" :isDisabled="!turf.editing">
                        </timePicker>
                      </b-col>

                      <b-col md="4">
                        <timePicker label="Off-Peak Day Peak End Time" label-for="off-peak-day-peak-end-time"
                          v-model="turf.off_peak_day_peak_end_time" :isDisabled="!turf.editing">
                        </timePicker>
                      </b-col>

                      <b-col md="4">
                        <timePicker label="Off-Peak Day Off Peak Start Time"
                          label-for="off-peak-day-off-peak-start-time" v-model="turf.off_peak_day_off_peak_start_time"
                          :isDisabled="!turf.editing">
                        </timePicker>
                      </b-col>

                      <b-col md="4">
                        <timePicker label="Off-Peak Day Off Peak End Time" label-for="off-peak-day-off-peak-end-time"
                          v-model="turf.off_peak_day_off_peak_end_time" :isDisabled="!turf.editing">
                        </timePicker>
                      </b-col>

                      <b-col md="4">
                        <InputField id="off_peak_day_peak_hours_charges" v-model="turf.off_peak_day_peak_hours_charges"
                          type="text" placeholder="Off Peak Day Peak Hours Charges" :isDisabled="!turf.editing">
                        </InputField>
                      </b-col>

                      <b-col md="4">
                        <InputField id="off_peak_day_peak_hours_charges"
                          v-model="turf.off_peak_day_off_peak_hours_charges" type="text"
                          placeholder="Off Peak Day Peak Hours Charges" :isDisabled="!turf.editing">
                        </InputField>
                      </b-col>

                      <!-- Timeslots Section -->
                      <b-col md="12">
                        <b-form-text class="p-2">
                          <strong>
                            Select Timeslots for {{ turf.turf_name }}
                          </strong>
                        </b-form-text>
                        <div class="timeslots mb-3">
                          <div v-for="slot in turf.timeslots" :key="slot.id" @click="toggleTimeslot(turf.id, slot.id)"
                            :disabled="!turf.editing" :class="[
                              'timeslot',
                              {
                                'timeslot-selected': isSelected(
                                  turf.id,
                                  slot.id
                                ),
                                'timeslot-disabled': !turf.editing,
                              },
                            ]" class="p-2 m-1 border">
                            {{ slot.time_slot }}
                          </div>
                        </div>
                      </b-col>

                      <b-col md="6"></b-col>
                    </b-row>
                    <div class="d-flex justify-content-center gap-2">
                      <b-button class="turf-btn" @click="toggleEdit(turf)" v-if="!turf.editing">Edit {{
                        turf.turf_name
                      }}</b-button>
                      <b-button class="turf-btn" @click="onSubmit(turf.id)" v-if="turf.editing">Save Changes</b-button>
                      <b-button class="turf-btn" @click="toggleCancel(turf)" v-if="turf.editing">Cancel</b-button>
                    </div>

                    <div class="d-flex justify-content-center flex-wrap mt-4 register g-2"></div>
                  </b-form>
                </b-card>
              </b-tab>
            </b-tabs>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else>
      <!-- <h1 class="slogan-text">FIELD FELLAS</h1> -->
      <ParallaxText />
      <div class="py-5">
        <b-row>
          <b-col>
            <h1 class="text-center">
              <i class="fas fa-exclamation-triangle text-danger"></i>
              <strong> No Turfs Found! </strong>
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-center ">
            <b-button variant="danger" @click="$router.push({ name: 'add-turf' })" class="my-5 btn-danger">Go to Add
              Turf</b-button>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/../config";
import Multiselect from "vue-multiselect";
import TimePicker from "@/components/TimePicker";
import InputField from "@/components/InputField";
import ParallaxText from "../ParallaxText.vue";

export default {
  components: {
    Multiselect,
    TimePicker,
    InputField,
    ParallaxText
  },
  data() {
    return {
      loading: false,
      turfs: [
        {
          id: null,
          turf_name: "",
          turf_size: "",
          turf_image: null,
          turf_status: "",
          editing: false,
          timeslots: [],
          selectedPeakDays: [],
          peak_day_peak_start_time: null,
          peak_day_peak_end_time: null,
          peak_day_off_peak_start_time: null,
          peak_day_off_peak_end_time: null,
          peak_day_peak_hours_charges: null,
          peak_day_off_peak_hours_charges: null,
          selectedoffPeakDays: [],
          off_peak_day_peak_start_time: null,
          off_peak_day_peak_end_time: null,
          off_peak_day_off_peak_start_time: null,
          off_peak_day_off_peak_end_time: null,
          off_peak_day_peak_hours_charges: null,
          off_peak_day_off_peak_hours_charges: null,
        },
      ],
      statusOptions: [
        { value: "", text: "Select Status" },
        { value: "active", text: "Active" },
        { value: "inactive", text: "Inactive" },
      ],
      days: [
        { value: "monday", text: "Monday" },
        { value: "tuesday", text: "Tuesday" },
        { value: "wednesday", text: "Wednesday" },
        { value: "thursday", text: "Thursday" },
        { value: "friday", text: "Friday" },
        { value: "saturday", text: "Saturday" },
        { value: "sunday", text: "Sunday" },
      ],
      activeTab: 0,
      show: true,
      turfImage: null,
      selectedTimeslots: {},
    };
  },
  created() {
    this.fetchAllTurfs();
  },
  computed: {
    turfImageFile() {
      return this.turf.turf_image instanceof File ? this.turf.turf_image : null;
    },
    turfImageFileName() {
      return this.extractFileName(this.turf.turf_image);
    },
  },

  methods: {
    formatTimeTo12Hour(timeStr) {
      const [hours] = timeStr.split(":");
      return parseInt(hours, 10).toString();
    },
    addTag(newTag) {
      const tag = {
        text: newTag,
        value: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      if (this.form.selectedPeakDays) {
        this.days.push(tag);
        this.form.selectedPeakDays.push(tag);
      } else {
        this.days.push(tag);
        this.form.selectedoffPeakDays.push(tag);
      }
    },
    extractFileName(file) {
      if (!file) return "";
      if (typeof file === "string") {
        return file.split("/").pop(); // handle URL
      } else if (file instanceof File) {
        return file.name; // handle File object
      }
      return "";
    },
    updateTurfImage(event, turf) {
      turf.turf_image = event.target.files[0];
    },
    onSubmit(turfId) {
      this.loading = true;
      const turf = this.turfs.find((turf) => turf.id === turfId);
      const formData = new FormData();
      formData.append("turf_id", turf.id);
      formData.append("turf_name", turf.turf_name);
      formData.append("turf_size", turf.turf_size);
      formData.append("turf_image", turf.turf_image);
      formData.append("turf_status", turf.turf_status);
      formData.append("timeslots", this.selectedTimeslots[turfId] || []);
      formData.append(
        "peak_days",
        turf.selectedPeakDays.map((day) => day.value)
      );
      formData.append(
        "off_peak_days",
        turf.selectedoffPeakDays.map((day) => day.value)
      );
      formData.append(
        "peak_day_peak_start_time",
        turf.peak_day_peak_start_time
      );
      formData.append("peak_day_peak_end_time", turf.peak_day_peak_end_time);
      formData.append(
        "peak_day_off_peak_start_time",
        turf.peak_day_off_peak_start_time
      );
      formData.append(
        "peak_day_off_peak_end_time",
        turf.peak_day_off_peak_end_time
      );
      formData.append(
        "peak_day_peak_hours_charges",
        turf.peak_day_peak_hours_charges
      );
      formData.append(
        "peak_day_off_peak_hours_charges",
        turf.peak_day_off_peak_hours_charges
      );

      formData.append(
        "off_peak_day_peak_start_time",
        turf.off_peak_day_peak_start_time
      );
      formData.append(
        "off_peak_day_peak_end_time",
        turf.off_peak_day_peak_end_time
      );
      formData.append(
        "off_peak_day_off_peak_start_time",
        turf.off_peak_day_off_peak_start_time
      );
      formData.append(
        "off_peak_day_off_peak_end_time",
        turf.off_peak_day_off_peak_end_time
      );
      formData.append(
        "off_peak_day_peak_hours_charges",
        turf.off_peak_day_peak_hours_charges
      );
      formData.append(
        "off_peak_day_off_peak_hours_charges",
        turf.off_peak_day_off_peak_hours_charges
      );

      axios
        .post(`${BASE_URL}/edit-turf`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.$showSuccessMessageToast(response.data.message);
          turf.editing = false;
          this.fetchAllTurfs();
        })
        .catch((error) => {
          this.$showErrorMessageToast(error.response.data.error || error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchAllTurfs() {
      this.loading = true;
      axios
        .get(`${BASE_URL}/get-all-turfs`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          this.turfs = response.data.data;
          this.turfs.forEach((turf) => {
            this.selectedTimeslots[turf.id] = [];
            turf.timeslots.forEach((slot) => {
              if (slot.timeslot_status === "sp_booked") {
                this.selectedTimeslots[turf.id].push(slot.id);
              }
            });

            turf.selectedPeakDays = turf.peak_days.days.map((day) => ({
              value: day.value,
              // text: day.text.toUpperCase(),
              text: day.text.charAt(0).toUpperCase() + day.text.slice(1),
            }));

            turf.selectedoffPeakDays = turf.off_peak_days.days.map((day) => ({
              value: day.value,
              // text: day.text.toUpperCase(),
              text: day.text.charAt(0).toUpperCase() + day.text.slice(1),
            }));
            turf.peak_day_peak_start_time = this.formatTimeTo12Hour(
              turf.peak_days.peak_hour_start
            );

            turf.peak_day_peak_end_time = this.formatTimeTo12Hour(
              turf.peak_days.peak_hour_end
            );

            turf.peak_day_off_peak_start_time = this.formatTimeTo12Hour(
              turf.peak_days.off_peak_hour_start
            );

            turf.peak_day_off_peak_end_time = this.formatTimeTo12Hour(
              turf.peak_days.off_peak_hour_end
            );

            turf.peak_day_peak_hours_charges = turf.peak_days.peak_hour_rate;
            turf.peak_day_off_peak_hours_charges =
              turf.peak_days.off_peak_hour_rate;

            turf.off_peak_day_peak_start_time = this.formatTimeTo12Hour(
              turf.off_peak_days.peak_hour_start
            );

            turf.off_peak_day_peak_end_time = this.formatTimeTo12Hour(
              turf.off_peak_days.peak_hour_end
            );

            turf.off_peak_day_off_peak_start_time = this.formatTimeTo12Hour(
              turf.off_peak_days.off_peak_hour_start
            );

            turf.off_peak_day_off_peak_end_time = this.formatTimeTo12Hour(
              turf.off_peak_days.off_peak_hour_end
            );

            turf.off_peak_day_peak_hours_charges =
              turf.off_peak_days.peak_hour_rate;
            turf.off_peak_day_off_peak_hours_charges =
              turf.off_peak_days.off_peak_hour_rate;
          });

          // this.$showSuccessMessageToast("Turfs fetched successfully!");
        })
        .catch((error) => {
          this.$showErrorMessageToast(error.response.data.error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onReset() { },
    toggleEdit(turf) {
      this.turfs = this.turfs.map((item) =>
        item.id === turf.id ? { ...item, editing: !item.editing } : item
      );
    },
    toggleCancel(turf) {
      this.turfs = this.turfs.map((item) =>
        item.id === turf.id ? { ...item, editing: false } : item
      );
    },
    toggleTimeslot(turfId, slotId) {
      if (!this.selectedTimeslots[turfId]) {
        this.$set(this.selectedTimeslots, turfId, []);
      }
      const index = this.selectedTimeslots[turfId].indexOf(slotId);
      if (index === -1) {
        this.selectedTimeslots[turfId].push(slotId);
        this.$forceUpdate();
      } else {
        this.selectedTimeslots[turfId].splice(index, 1);
        this.$forceUpdate();
      }
    },
    isSelected(turfId, slotId) {
      return (
        this.selectedTimeslots[turfId] &&
        this.selectedTimeslots[turfId].includes(slotId)
      );
    },
  },

};
</script>

<style scoped>
.turf-image-upload {
  position: relative;
  padding-bottom: 0px;
}

.timeslots {
  display: flex;
  flex-wrap: wrap;
}

.timeslot {
  cursor: pointer;
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  text-align: center;
}

.gap-2 {
  gap: .5rem;
}

.timeslot-selected {
  background-color: #00853e;
  color: white;
}

.timeslot-disabled {
  background: #e9ecef;
  pointer-events: none;
  cursor: not-allowed;
  color: black;
}

.turf-image-upload>span {
  position: absolute;
  top: 2.9rem;
  left: 0.8rem;
  cursor: not-allowed;
  background: #e9ecef;
  border-radius: 0.3rem;
  padding-inline: 0.5rem;
  /* border: 1px solid; */
}

.form-text {
  font-size: 1rem;
  background: white;
  color: #555 !important;
}

::v-deep .nav-pills .nav-link.active {
  color: white;
  background: #00853e;
}

::v-deep .nav-pills .nav-link {
  border: 1px solid #dee2e6;
  color: #00853e;
  margin-bottom: 0.5rem;
}

.form-control:disabled {
  /* cursor: not-allowed; */
}

.option-setting[disabled] {
  /* cursor: not-allowed; */
  background: #e9ecef;
}

/* .turf-btn {
  width: fit-content;
  color: white;
  padding-inline: 2rem;
} */

.circle-red {
  position: absolute;
  top: 0.88rem;
  left: 6rem;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #df1620;
  border-radius: 50%;
}

.circle-green {
  position: absolute;
  top: 0.88rem;
  left: 5.15rem;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #6ec531;
  border-radius: 50%;
}

select:disabled {
  cursor: default;
}

.image-name {
  /* position: absolute; */
  right: 1rem;
  top: 9px;
  background: #ececef;
  padding: 0.02rem 0.3rem;
  font-size: 12px;
  border-radius: 10px;
  font-weight: 600;
}
</style>

<template>
  <div class="mb-3" v-if="this.$store.state.user.role_type !== 'admin'">
    <h4>Booking Record</h4>

    <div class="filter-controls pb-2">
      <b-form-group>
        <b-form-input type="date" v-model="startDate"></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-form-input type="date" v-model="endDate"></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-form-select
          v-model="bookingType"
          :options="bookingTypes"
        ></b-form-select>
      </b-form-group>
      <b-button @click="fetchBookings">Filter</b-button>
    </div>

    <b-table
      show-empty
      responsive
      hover
      scrollable
      small
      :items="bookings"
      :fields="filteredFields"
      class="custom-table"
      head-variant="dark"
    ></b-table>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "../../config";

export default {
  data() {
    return {
      bookings: [],
      startDate: "",
      endDate: "",
      bookingType: null,
      bookingTypes: [
        { value: null, text: "All" },
        { value: "pending", text: "Pending" },
        { value: "booked", text: "Booked" },
        { value: "cancelled", text: "Cancelled" },
      ],
      tableFields: [
        { key: "id", label: "Booking Id", sortable: true },
        { key: "customer_name", label: "Customer Name", sortable: true },
        { key: "club_name", label: "Club Name", sortable: true },
        { key: "turf_name", label: "Turf Id", sortable: true },
        { key: "time_slots", label: "Time Slots", sortable: true },
        { key: "date", label: "Date", sortable: true },
        { key: "total_amount", label: "Total Amount", sortable: true },
        { key: "booking_status", label: "Status", sortable: true },
      ],
    };
  },
  computed: {
    filteredFields() {
      if (this.$store.state.user.role_type === "customer") {
        return this.tableFields.filter(
          (field) => field.key !== "customer_name"
        );
      } else if (this.$store.state.user.role_type === "service_provider") {
        return this.tableFields.filter((field) => field.key !== "club_name");
      }
      return this.tableFields;
    },
  },
  mounted() {
    if (this.$store.state.user.role_type !== "admin") {
      this.fetchBookings();
    }
  },
  methods: {
    async fetchBookings() {
      const roleType = this.$store.state.user.role_type;
      let apiUrl = `${BASE_URL}/get-customer-booking`; // Default API URL
      const requestData = {}; // Default request body (empty for POST requests)

      // Validate dates
      if (
        (this.startDate && !this.endDate) ||
        (!this.startDate && this.endDate)
      ) {
        this.$showErrorMessageToast(
          "Both start date and end date must be provided."
        );
        return;
      }

      // Add query parameters
      const params = {};
      if (this.startDate) params.start_date = this.startDate;
      if (this.endDate) params.end_date = this.endDate;
      if (this.bookingType) params.booking_type = this.bookingType;

      if (roleType === "service_provider") {
        apiUrl = `${BASE_URL}/get-vendor-bookings`; // API URL for service providers
      }

      try {
        const response =
          roleType === "service_provider"
            ? await axios.post(apiUrl, requestData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                params, // Include query parameters
              })
            : await axios.get(apiUrl, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                params,
              }); // Include query parameters
        if (response.data.success) {
          this.bookings = response.data.data.map((booking) => {
            const timeslots = booking.timeslots;

            // Determine how to format the time slots
            let timeSlotsDisplay = "";
            if (timeslots && timeslots.length > 0) {
              const timeRanges = [];
              let currentRange = [timeslots[0]];

              for (let i = 1; i < timeslots.length; i++) {
                const currentSlot = timeslots[i];
                const previousSlot = currentRange[currentRange.length - 1];

                // Check if the current slot is consecutive with the previous slot
                const previousEndTime = previousSlot.time_slot.split("-")[1];
                const currentStartTime = currentSlot.time_slot.split("-")[0];

                if (previousEndTime === currentStartTime) {
                  // If consecutive, add to the current range
                  currentRange.push(currentSlot);
                } else {
                  // If not consecutive, finalize the current range
                  const rangeStart = currentRange[0].time_slot.split("-")[0];
                  const rangeEnd =
                    currentRange[currentRange.length - 1].time_slot.split(
                      "-"
                    )[1];
                  timeRanges.push(
                    currentRange.length > 1
                      ? `${rangeStart}-${rangeEnd}`
                      : currentRange[0].time_slot
                  );

                  // Start a new range
                  currentRange = [currentSlot];
                }
              }

              // Add the final range
              const rangeStart = currentRange[0].time_slot.split("-")[0];
              const rangeEnd =
                currentRange[currentRange.length - 1].time_slot.split("-")[1];
              timeRanges.push(
                currentRange.length > 1
                  ? `${rangeStart}-${rangeEnd}`
                  : currentRange[0].time_slot
              );

              // Join all ranges with commas
              timeSlotsDisplay = timeRanges.join(", ");
            }

            return {
              id: booking.id,
              turf_name: booking.turf_name,
              club_name: booking.club_name,
              time_slots: timeSlotsDisplay, // Show formatted time slots
              date: booking.date,
              total_amount: `Rs ${booking.total_amount}`,
              booking_status:
                booking.booking_status === "temp_booked"
                  ? "Payment Pending"
                  : booking.booking_status === "booked"
                  ? "Booked"
                  : "Cancelled",
              customer_name: booking.customer_name,
            };
          });
        } else {
          this.$showErrorMessageToast("No bookings found");
        }
      } catch (error) {
        this.$showErrorMessageToast(error);
      }
    },
  },
};
</script>

<style scoped>
::v-deep td {
  font-size: 14px;
}

::v-deep th {
  font-weight: 600;
  font-size: 14px;
  color: #404041 !important;
}

h4 {
  font-weight: 600;
  text-align: center;
}

.card {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.custom-table.table-responsive {
  margin-bottom: 0px;
  max-height: 300px !important;
  overflow-y: auto;
}

.filter-controls {
  display: flex;
  justify-content: end;
  gap: 1rem;
}
</style>

<template>
  <div class="main-container mb-5">
    <WebLoader v-if="loading" />
    <ParallaxText class="mt-3" />
    <b-container class="tab-container" v-if="turfs && turfs.length > 0">
      <b-tabs v-model="activeTab" card>
        <b-tab v-for="(turf, index) in turfs" :key="index" :title="turf.turf_name">
          <b-container class="mb-3">
            <b-row>
              <b-col md="4" class="mb-2 left-container">
                <div class="image-container">
                  <b-img :src="turf.turf_image" alt="Turf image"></b-img>
                </div>
                <div class="details-container my-3">
                  <div class="detail-header">
                    <div class="heading">
                      <h5 class="fw-bold">Turf Details</h5>
                    </div>
                    <div class="location-redirect">
                      <a :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(turf.location_name)}`"
                        target="_blank">
                        <i class="fas fa-map-marker-alt"></i>
                        Get Directions
                      </a>

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <h6>
                        Day Type:
                      </h6>
                    </div>
                    <div class="col-6">
                      <span class="text-primary">{{
                        turf.details.day_type == "peak" ? "Peak" : "Off-Peak"
                      }}</span>
                    </div>

                    <div class="col-6">
                      <h6>
                        Peak Hour:
                      </h6>
                    </div>
                    <div class="col-6">
                      <span class="text-primary">{{
                        turf.details.peak_hour_time
                      }}</span>
                    </div>
                    <div class="col-6">

                      <h6>
                        Off-Peak Hour:
                      </h6>
                    </div>
                    <div class="col-6">
                      <span class="text-primary">{{
                        turf.details.off_peak_hour_time
                      }}</span>
                    </div>
                    <div class="col-6">
                      <h6>
                        Peak Hour Rate:
                      </h6>
                    </div>
                    <div class="col-6">
                      <span class="text-primary">{{
                        turf.details.peak_hour_rate + " PKR"
                      }}</span>
                    </div>
                    <div class="col-6">

                      <h6>
                        Off-Peak Hour Rate:
                      </h6>
                    </div>
                    <div class="col-6">
                      <span class="text-primary">{{
                        turf.details.off_peak_hour_rate + " PKR"
                      }}</span>
                    </div>
                  </div>


                </div>
              </b-col>
              <b-col md="8">
                <h6>Selected Date is:</h6>
                <Datepicker class="date-picker" v-model="selectedDate" :config="datePickerConfig"
                  @input="fetchTurfDetailsOnDateChange" :disabledDates="disabledDates"></Datepicker>
                <h6>Timeslots are:</h6>
                <div class="timeslots">
                  <div class="timeslot" v-for="(timeSlot, timeSlotIndex) in turf.timeslotss" :key="timeSlotIndex"
                    @click="toggleTimeSlot(index, timeSlotIndex)" :class="{
                      selected: isSelected(index, timeSlotIndex),
                      tempBooked: isTempBooked(timeSlot),
                      booked: isBooked(timeSlot),
                    }" :style="getStyle(timeSlot)">
                    {{ timeSlot.time_slot }}
                    <div class="timeslot-timer" v-if="isTempBooked(timeSlot)">
                      <i class="fa fa-clock" aria-hidden="true">
                        {{ formatCountdown(countdowns[turf.id][timeSlot.id]) }}
                        <!-- {{ timeSlot.timeslot_timer }} -->
                      </i>
                    </div>
                  </div>
                </div>
                <div class="status-container">
                  <div class="status-item">
                    <div class="available-slot-box"></div>
                    <span>- Available Slot</span>
                  </div>
                  <div class="status-item">
                    <div class="temp-booked-box"></div>
                    <span>- Payment Pending</span>
                  </div>
                  <div class="status-item">
                    <div class="booked-box"></div>
                    <span>- Booked</span>
                  </div>
                </div>

                <div class="btn-container">
                  <b-button class="btn-secondary" @click="finalizeBooking(index)">Finalize Booking</b-button>
                  <b-button variant="danger" @click="backToClub">Back</b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
    </b-container>
    <b-container v-else class="pb-3">
      <b-row>
        <b-col>
          <h1 class="py-5 h-100 text-center d-flex justify-content-center align-items-center">
            <strong>
              <i class="fas fa-exclamation-triangle text-danger"></i>
              No Turf Found
            </strong>
          </h1>
        </b-col>
        <b-col md="12" class="text-center">
          <b-button variant="danger" @click="backToClub" class=" btn-danger my-5 ">Back to Club</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { mapGetters } from "vuex";
import { BASE_URL } from "@/../config";
import moment from "moment";
import ParallaxText from "../ParallaxText.vue";

export default {
  components: {
    Datepicker,
    ParallaxText
  },
  data() {
    return {
      selectedDate: new Date(),
      datePickerConfig: {
        dateFormat: "Y-m-d",
      },
      countdowns: {},
      countdownInterval: null,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      activeTab: 0,
      tabValue: 0,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["turfDetails"]),
    turfs() {
      return this.turfDetails;
    },
  },

  watch: {},
  created() {
    this.initializeCountdown();
    const vendorId = this.turfDetails[0]?.vendor_id;
    let currentRoute = this.$route.name;
    let previousRoute = this.$route.meta.previousRoute;

    if (previousRoute === null) {
      previousRoute = currentRoute;
    }

    // console.log("Current Route:", currentRoute);
    // console.log("Previous Route:", previousRoute);

    if (currentRoute === previousRoute) {
      // console.log("Same route, fetching data...");
      if (!vendorId) {
        // console.log("No vendor ID found, fetching from API...");
        this.$showErrorMessageToast("No vendor ID found, please try again");
        this.fetchTurfDetailsOnDateChange(); // Fetch the data from the API if vendorId is not present in state
      } else {
        // console.log("Vendor ID:", vendorId);
        this.fetchTurfDetailsOnDateChange(); // Always fetch updated data on page load/refresh
      }
    }
  },
  destroyed() {
    clearInterval(this.countdownInterval); // Clear the interval
    this.$store.commit("setTurfDetails", []);
  },
  methods: {
    initializeCountdown() {
      this.turfs.forEach(turf => {
        this.$set(this.countdowns, turf.id, {}); // Initialize an object for each turf's countdowns
        turf.timeslotss.forEach(timeSlot => {
          if (timeSlot.timeslot_status === 'temp_booked' && timeSlot.timeslot_timer) {
            const endTime = moment(timeSlot.timeslot_timer);
            const now = moment();
            const duration = moment.duration(endTime.diff(now));

            if (duration.asSeconds() > 0) {
              this.$set(this.countdowns[turf.id], timeSlot.id, duration.asSeconds());
            }
          }
        });
      });

      this.startCountdown();
    },

    startCountdown() {
      // Clear any existing interval
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }

      // Start a new interval
      this.countdownInterval = setInterval(() => {
        Object.keys(this.countdowns).forEach(turfId => {
          Object.keys(this.countdowns[turfId]).forEach(key => {
            if (this.countdowns[turfId][key] > 0) {
              this.countdowns[turfId][key]--;
            } else {
              this.$delete(this.countdowns[turfId], key); // Remove countdown when it reaches zero
            }
          });
        });
      }, 1000);
    },

    formatCountdown(seconds) {

      if (seconds > 0) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60); // Use Math.floor to avoid decimals

        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
      }
      else if (seconds <= 0) {
        return "00:00"; // Return "00:00" if the countdown has finished
      }

      else {
        return "00:00"; // Return "00:00" if the countdown has finished
      }
    },
    async fetchTurfDetailsOnDateChange() {
      this.loading = true;
      const vendorId = this.turfDetails[0]?.vendor_id;
      const selectedDate = moment(this.selectedDate).format("YYYY-MM-DD");
      // console.log("Selected Date:", selectedDate);
      // console.log("Vendor ID:", vendorId);
      try {
        const response = await axios.get(
          `${BASE_URL}/get-vendor-turfs/${vendorId}?date=${selectedDate}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const turfDetails = response.data.data;
        // console.log("Fetching turf time details:", turfDetails);

        this.$store.commit("setTurfDetails", turfDetails);

        // Re-initialize the countdowns after fetching new turf details
        this.initializeCountdown();
      } catch (error) {
        // console.error("Error fetching turf details:", error);
        this.$showErrorMessageToast("Error fetching turf details:", error);
      } finally {
        this.loading = false;
      }
    },
    toggleTimeSlot(tabIndex, timeSlotIndex) {
      const timeSlot = this.turfs[tabIndex].timeslotss[timeSlotIndex];
      if (
        timeSlot.timeslot_status === "temp_booked" ||
        timeSlot.timeslot_status === "booked"
      ) {
        return; // Do nothing if the timeslot is temp_booked or booked
      }

      const selectedTimeSlots = this.turfs[tabIndex].selectedTimeSlots || [];
      if (!selectedTimeSlots.includes(timeSlotIndex)) {
        selectedTimeSlots.push(timeSlotIndex);
      } else {
        selectedTimeSlots.splice(selectedTimeSlots.indexOf(timeSlotIndex), 1);
      }
      this.$set(this.turfs[tabIndex], "selectedTimeSlots", selectedTimeSlots);
    },
    isSelected(tabIndex, timeSlotIndex) {
      return (this.turfs[tabIndex].selectedTimeSlots || []).includes(
        timeSlotIndex
      );
    },
    isTempBooked(timeSlot) {
      return timeSlot.timeslot_status === "temp_booked";
    },
    isBooked(timeSlot) {
      return timeSlot.timeslot_status === "booked";
    },
    getStyle(timeSlot) {
      if (timeSlot.timeslot_status === "temp_booked") {
        return { backgroundColor: "#ffc107", cursor: "not-allowed" };
      } else if (timeSlot.timeslot_status === "booked") {
        return { backgroundColor: "#dc3545", cursor: "not-allowed" };
      }
      return {};
    },
    async finalizeBooking(tabIndex) {
      const selectedSlots = (this.turfs[tabIndex].selectedTimeSlots || []).map(
        (index) => this.turfs[tabIndex].timeslotss[index].id
      );

      if (selectedSlots.length === 0) {
        this.$showErrorMessageToast(
          "Please select a timeslot to proceed further"
        );
        return;
      }
      this.loading = true;

      const formattedDate = moment(this.selectedDate).format("YYYY-MM-DD");

      let formData = new FormData();
      formData.append("vendor_id", this.turfs[tabIndex].vendor_id);
      formData.append("turf_id", this.turfs[tabIndex].id);
      formData.append("customer_id", this.$store.state.user.id);
      formData.append("time_slot_ids", selectedSlots);
      formData.append("date", formattedDate);

      try {
        const response = await axios.post(
          `${BASE_URL}/create-booking`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        this.$showSuccessMessageToast(response.data.message);
        this.$router.push({
          name: "Booking",
        });
        // Fetch the updated turf details after successful booking
        await this.$store.dispatch(
          "fetchTurfDetails",
          this.turfs[tabIndex].vendor_id
        );

        // Log the vendor and turf details to the console
        // console.log("Turf Details:", this.turfs[tabIndex]);

      } catch (error) {
        this.$showErrorMessageToast(error.response.data.error);
      }
      finally {
        this.loading = false;
      }
    },

    backToClub() {
      this.$router.push("/club");
    },
  },
};
</script>

<style scoped>
::v-deep .card-header-tabs {
  margin-left: 5px !important;
}

::v-deep .card-header-tabs .nav-link.active {
  color: white !important;
  background: #00853e !important;
}

@keyframes moveHeading {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

::v-deep .card-header {
  margin-bottom: 1rem;
}

::v-deep .nav-link {
  border: 1px solid #dee2e6 !important;
  color: #00853e !important;
}

.card-gap {
  gap: 3rem;
}

::v-deep .nav-tabs {
  border: none !important;
}

::v-deep .nav-tabs li {
  padding: 0.5rem;
}

::v-deep .nav-link {
  max-width: 140px !important;
  white-space: nowrap !important;
}

::v-deep .nav-link.active {
  background-color: #404041 !important;
  color: white !important;
}

img {
  width: 100%;
  border-radius: 10px;
}

.timeslots {
  display: flex;
  flex-wrap: wrap;
}

.timeslot {
  cursor: pointer;
  width: 8rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: .3rem;
  padding: 0.675rem;
  text-align: center;
  background: #f5f5f5;
  position: relative;

}

.timeslot-timer {
  position: absolute;
  font-size: 0.725rem;
  left: 2.5rem;
  bottom: 0rem;
}

.selected {
  background-color: #00853e !important;
  color: white !important;
}

.btn-container {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  margin-left: 5px;
}

::v-deep .date-picker>div>input {
  padding: 0.5rem !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  margin-bottom: 0.5rem !important;
  margin-left: 5px;
}

::v-deep .disabled {
  cursor: not-allowed !important;
}

::v-deep .vdp-datepicker__calendar .cell.selected {
  background-color: #00853e !important;
  color: white !important;
}

::v-deep .vdp-datepicker__calendar .cell.selected:hover {
  background-color: #00853e !important;
  color: white !important;
}

::v-deep .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
  border: 1px solid #404041 !important;
}

::v-deep .vdp-datepicker__calendar {
  border-radius: 5px !important;
}

.image-container {
  height: 15.625rem;
  width: 100%;
  overflow: hidden;
}

.image-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.text-primary {
  color: #00853e !important;
}

.details-container {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.detail-header {
  display: flex;
  justify-content: space-between
}

.location-redirect>a {
  color: #0d6efd !important;
  text-decoration: none !important
}


.location-redirect>a:hover {
  color: #404041 !important;
}

.status-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* Space between items */
  padding-inline: .4rem;
}

.status-item {
  display: flex;
  align-items: center;
  gap: 5px;
  /* Space between the box and text */
}

.temp-booked-box {
  width: 0.875rem;
  height: 0.875rem;
  background-color: #ffc107;
  /* Yellow color for temporary booked */
  border: 1px solid #ccc;
}

.available-slot-box {
  width: 0.875rem;
  height: 0.875rem;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.booked-box {
  width: 0.875rem;
  height: 0.875rem;
  background-color: #dc3545;
  /* Red color for booked */
  border: 1px solid #ccc;
}

span {
  font-size: 14px;
  color: #333;
  /* Text color */
}
</style>

<template>
    <div>
        <WebLoader v-if="loading" />
        <b-container id="mainContainer" align-v="center">
            <CustomBg />
            <b-row align-v="center" align-h="center">
                <b-col md="6">
                    <div>
                        <b-card id="cardLogin" class="scale-in-bl my-3">
                            <b-form @submit="onSubmit" id="formLogin">
                                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="blob">
                                    <path fill="#00853e"
                                        d="M66.2,-49.1C81.5,-33.5,86.8,-6.2,79.8,15.6C72.7,37.5,53.3,53.9,30.9,65.1C8.5,76.2,-16.9,81.9,-36.2,73.3C-55.5,64.7,-68.8,41.7,-70.8,19.9C-72.8,-2,-63.6,-22.7,-49.9,-37.8C-36.2,-53,-18.1,-62.7,3.6,-65.6C25.4,-68.5,50.8,-64.6,66.2,-49.1Z"
                                        transform="translate(100 100)" />
                                </svg>

                                <div class="otp-image text-center">
                                    <img style="text-align: center;" src="@/assets/opt-1.png" alt="Team Member"
                                        thumbnail fluid rounded-circle mb-3 width="100" />
                                </div>
                                <h1 class="text-center mb-3">One Time Password</h1>
                                <h6 class="text-center mb-4">
                                    Enter the 5-digit OTP sent to your registered email address.
                                </h6>

                                <!-- OTP Input Fields -->
                                <div class="otp-input-container d-flex justify-content-center">
                                    <input v-for="(digit, index) in otp" :key="index" type="text" maxlength="1"
                                        class="otp-input" v-model="otp[index]" :ref="'otp' + index"
                                        @input="handleInput(index)" @keydown.backspace="focusPrev(index, $event)"
                                        @keypress="allowOnlyNumbers" />
                                </div>

                                <div class="d-flex justify-content-center mt-3">
                                    <a @click="onSubmit" href="#" class="recover-arrow-btn">
                                        <img class="arrow-img" src="../assets/images/arrow.png" alt="" srcset="" />
                                    </a>
                                </div>

                                <div class="d-flex justify-content-center flex-wrap mt-4 register g-2">
                                    <span class="mr-2">Didn't receive OTP?
                                    </span>
                                    <a @click="$router.go(-1)" class="loginAccount">Resend OTP</a>
                                </div>

                            </b-form>
                        </b-card>
                    </div>
                </b-col>
                <b-col md="6">
                    <img src="../assets/images/business-coffee.png" fluid class="businessImg scale-in-bottom" center />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from "axios";
import CustomBg from "../components/CustomBg";
import { BASE_URL } from "../../config";
import WebLoader from "./WebLoader.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        CustomBg,
        WebLoader,
    },
    data() {
        return {
            otp: Array(5).fill(""), // Array to store OTP digits
            loading: false,
        };
    },
    mounted() {
        // console.log("Email in otp page is:", this.getEmail)
    },
    computed: {
        ...mapGetters(['getEmail']),
    },
    methods: {
        ...mapActions(["setEmail"]),
        handleInput(index) {
            const value = this.otp[index];
            if (value && index < this.otp.length - 1) {
                this.$refs[`otp${index + 1}`][0].focus();
            }
        },
        focusPrev(index, event) {
            if (event.target.value === "" && index > 0) {
                this.$refs[`otp${index - 1}`][0].focus();
            }
        },
        allowOnlyNumbers(event) {
            const charCode = event.which ? event.which : event.keyCode;
            if (charCode < 48 || charCode > 57) {
                event.preventDefault(); // Block non-numeric inputs
            }
        },
        onSubmit() {
            this.loading = true;

            // Combine OTP digits into a single string
            const otpValue = this.otp.join("");

            if (otpValue.length < 5) {
                this.$showErrorMessageToast("Please enter all 5 digits of the OTP.");
                this.loading = false;
                return;
            }

            let data = {
                email: this.getEmail,
                otp: otpValue
            }

            axios
                .post(`${BASE_URL}/one-time-password`, data)
                .then((response) => {
                    this.$showSuccessMessageToast(response.data.message);
                    this.$router.push({ name: "login" });
                })
                .catch((error) => {
                    this.$showErrorMessageToast(
                        error.response?.data?.error || "Invalid OTP. Please try again."
                    );
                })
                .finally(() => {
                    this.loading = false;
                    // this.setEmail(null);
                });
        },
    },

}
</script>
<style lang="scss" scoped>
@import "../assets/scss/animations.scss";
@import "../assets/scss/variables.scss";
@import "../assets/scss/fonts.scss";

.customBg {
    right: 10%
}

.input-group-append {
    position: absolute;
    right: 0;

}

.otp-input-container {
    gap: 0.5rem;
}

.otp-input {
    width: 3rem;
    height: 3rem;
    text-align: center;
    font-size: 1.5rem;
    border: 2px solid #00853e;
    border-radius: 5px;
    outline: none;
    background-color: #282c350f;
}

.otp-input:focus {
    border-color: #00a651;
    box-shadow: 0 0 5px #00a651;
}

.input-group .btn {
    border-color: none;
    background-color: transparent;
    border: none;
}

.input {
    z-index: 1 !important;
}

.fa-eye,
.fa-eye-slash {
    color: $gray;
    cursor: pointer;
}

#mainContainer {
    width: 100%;
    height: auto;
    position: relative;
}

.g-2 {
    gap: 0.2rem;
}

body {
    font-family: $Poppins !important;
}

.arrow-img {
    height: 3rem;
}

#cardPassword {
    border-radius: 15px;
    box-shadow: 0px 0px 10px $gray;

    #formLogin {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        position: relative;

        h1 {
            color: $primary;
            font-weight: 700;
            font-family: $Poppins;
        }

        h2 {
            font-size: 1.2rem;
            color: $lightBlack;
            font-weight: 600;
            font-family: $Poppins;
        }

        .other-account {
            color: $gray;
        }

        .icons {
            width: 30px;
            cursor: pointer;
        }

        .input {
            border-radius: 10px;
        }

        .remember {
            color: $lightBlack;
        }

        .blob {
            position: absolute;
            top: -15%;
            left: -12%;
            width: 150px;
            opacity: 0.2;
        }
    }

    /* .logoLogin {
        margin: 0 auto;
        width: 100px;
      } */

    .forgotPassword {
        color: $gray;
        text-decoration: none;

        &:hover {
            color: $primary;
            transition: 0.5s ease-in-out;
        }
    }

    /* .btn-login {
        width: 200px;
        background-color: $primary;
        border: none;
        font-size: 1.3rem;
        font-weight: 600;
        border-radius: 10px;
    
        &:hover {
          background-color: $secondary;
          transition: 0.5s ease-in-out;
        }
      } */

    .recover-arrow-btn {
        // width: 100px;
        border-radius: 15px;
        background: $primary;
        // color: #fff;
        // font-size: 60px;
        // padding: 5px;
        box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);

        &:hover {
            background: $secondary;
            transition: 0.5s ease-in-out;
            box-shadow: none;
        }
    }

    .register {
        span {
            color: $lightBlack;
        }

        .loginAccount {
            // color: $primary;
            cursor: pointer;
        }
    }
}
</style>
<template>
  <b-container class="my-5">
    <div class="faq-container">
      <div class="row">
        <div class="col" md="6">
          <div
            class="flex-column h-100 text-center d-flex justify-content-center align-items-center"
          >
            <i class="fas fa-question-circle fa-3x"></i>
            <h1 class="font-w-800">Frequently Asked Questions</h1>
          </div>
        </div>
        <div class="col flex-column" md="6">
          <b-card
            v-for="(faq, index) in faqs"
            :key="index"
            class="mb-3 main-body"
          >
            <b-card-header
              class="d-flex justify-content-between align-items-center"
              @click="toggleAnswer(index)"
            >
              <span class="question-body">
                <strong>
                  {{ faq.question }}
                </strong>
              </span>
              <i class="fas" :class="faq.show ? 'fa-minus' : 'fa-plus'"></i>
            </b-card-header>
            <b-collapse :id="'collapse-' + index" v-model="faq.show">
              <b-card-body
                class="answer-body"
                v-html="faq.answer"
              ></b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          question: "How do I book a turf on Field Fellas?",
          answer:
            "Booking a turf is simple! Visit our website, browse through the available clubs and turfs, select your preferred time slot, and proceed to payment. You can make the payment securely through Jazz Cash.",
          show: false,
        },
        {
          question: "What payment methods do you accept?",
          answer:
            "We currently accept payments through Jazz Cash, which provides a secure and convenient way to complete your booking.",
          show: false,
        },
        {
          question: "Can I cancel my booking?",
          answer:
          "Yes, you can cancel your booking within 1 hour of making it, as long as you haven't made the payment. Once the payment is completed, you won't be able to cancel or reschedule your booking at this time.",
            // "Yes, you can cancel your booking up to 24 hours before the scheduled time slot. Please note that a cancellation fee may apply in future bookings if you cancel frequently.",
          show: false,
        },
        {
          question: "Do you offer any discounts or promotions?",
          answer:
            "We frequently run promotions and discounts on our website. Keep an eye out for our latest offers to get the best deals on turf bookings!",
          show: false,
        },
        {
          question: "How can I contact Field Fellas for support?",
          answer:
            "If you have any questions or need assistance, you can reach out to our customer support team at <a href='mailto:fieldfellas@gmail.com'>fieldfellas@gmail.com</a>",
          show: false,
        },

        // Add more FAQ objects here
      ],
    };
  },
  methods: {
    toggleAnswer(index) {
      this.faqs[index].show = !this.faqs[index].show;
    },
  },
};
</script>

<style scoped>
.faq-section b-card-header {
  cursor: pointer;
  user-select: none;
}
.main-body > .card-body {
  padding: 0;
}

.card-body > .card-header {
  border: none;
  cursor: pointer;
}

.answer-body {
  background-color: #21252908;
  border-top: rgba(0, 0, 0, 0.175) 1.5px solid;
  color: #404041;
}

.question-body {
  color: #00853e;
}

.font-w-800 {
  font-weight: 800;
  color: #fff;
}

.fa-question-circle {
  color: #fff;
}

.fa-plus,
.fa-minus {
  color: #404041;
}

.faq-container {
  background-color: #404041;
  border-radius: 1rem;
  padding: 2rem;
}
</style>

<template>
  <div>
    <WebLoader v-if="loading" />
    <b-container class="mt-5">
      <b-row class="main-body">
        <!-- Breadcrumb -->

        <!-- /Breadcrumb -->

        <b-col md="4" class="mb-3">
          <b-card class="pb-3 color-bg">
            <b-card-body class="mb-2">
              <div class="d-flex flex-column align-items-center text-center">
                <b-img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" rounded="circle"
                  width="150"></b-img>
                <div class="mt-3 card-text">
                  <h4>{{ name }}</h4>
                  <p class="mb-1">
                    Joined in
                    {{ new Date(this.created_at).getFullYear() || "-" }}
                  </p>
                  <!-- <p class="text-muted font-size-sm">
                    Bay Area, San Francisco, CA
                  </p> -->
                  <div class="button-container d-flex g-2">
                    <b-button class="edit-btn" @click="editProfile" v-if="!editingProfile">
                      Edit Profile
                    </b-button>
                    <b-button class="save-btn" @click="saveChanges" v-if="editingProfile">
                      Save Changes
                    </b-button>
                    <b-button class="save-btn" @click="cancelChanges" v-if="editingProfile">
                      Cancel
                    </b-button>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="8" class="mb-3">
          <!-- User Form -->
          <b-card class="card-shadow" v-if="!editingProfile">
            <b-card-body>
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Full Name</h6>
                </div>
                <div class="col-sm-9 text-secondary">{{ name }}</div>
              </div>
              <hr v-if="role_type === 'service_provider'" />
              <div class="row" v-if="role_type === 'service_provider'">
                <div class="col-sm-3">
                  <h6 class="mb-0">Club Name</h6>
                </div>
                <div class="col-sm-9 text-secondary">{{ club_name }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Email</h6>
                </div>
                <div class="col-sm-9 text-secondary">{{ email }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Contact No.</h6>
                </div>
                <div class="col-sm-9 text-secondary">{{ contact_number }}</div>
              </div>
              <hr v-if="role_type === 'service_provider'" />
              <div class="row" v-if="role_type === 'service_provider'">
                <div class="col-sm-3">
                  <h6 class="mb-0">Feature Yourself</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  <b-form-radio-group v-model="is_featured" :options="options" :disabled="!editingProfile"
                    class="d-flex gap-3 justify-content-start"></b-form-radio-group>
                </div>
              </div>
              <hr v-if="role_type === 'service_provider'" />
              <div class="row" v-if="role_type === 'service_provider'">
                <div class="col-sm-3">
                  <h6 class="mb-0">Club Availability</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  <b-form-radio-group v-model="is_available" :options="avaiable_options" :disabled="!editingProfile"
                    class="d-flex gap-3 justify-content-start"></b-form-radio-group>
                </div>
              </div>
              <hr v-if="role_type === 'service_provider'" />
              <div class="row" v-if="role_type === 'service_provider'">
                <div class="col-sm-3">
                  <h6 class="mb-0">Advance Payment</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  <b-form-radio-group v-model="advance_payment" :options="advance_options" :disabled="!editingProfile"
                    class="d-flex gap-3 justify-content-start"></b-form-radio-group>
                </div>
              </div>
            </b-card-body>
          </b-card>

          <!-- Edit Form  -->
          <b-card class="pb-3 card-shadow" v-if="editingProfile">
            <b-card-body>
              <div class="row mb-3">
                <div class="col-sm-3 d-flex vertical-middle">
                  <h6 class="mb-0">Full Name</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  <b-form-input type="text" v-model="name" class="form-control"></b-form-input>
                </div>
              </div>
              <div class="row mb-3" v-if="role_type === 'service_provider'">
                <div class="col-sm-3 d-flex vertical-middle">
                  <h6 class="mb-0">Club Name</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  <b-form-input type="text" v-model="club_name" class="form-control"></b-form-input>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-3 d-flex vertical-middle">
                  <h6 class="mb-0">Email</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  <b-form-input type="text" v-model="email" class="form-control"></b-form-input>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-3 d-flex vertical-middle">
                  <h6 class="mb-0">Contact No.</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  <b-form-input maxlength="11" type="text" v-model="contact_number" class="form-control"></b-form-input>
                </div>
              </div>
              <div class="row mb-3" v-if="role_type === 'service_provider'">
                <div class="col-sm-3 d-flex vertical-middle">
                  <h6 class="mb-0">Feature Yourself</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  <b-form-radio-group v-model="is_featured" :options="options"
                    class="d-flex gap-3 justify-content-start"></b-form-radio-group>
                </div>
              </div>
              <div class="row mb-3" v-if="role_type === 'service_provider'">
                <div class="col-sm-3 d-flex vertical-middle">
                  <h6 class="mb-0">Club Availability</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  <b-form-radio-group v-model="is_available" :options="avaiable_options"
                    class="d-flex gap-3 justify-content-start"></b-form-radio-group>
                </div>
              </div>
              <div class="row mb-3" v-if="role_type === 'service_provider'">
                <div class="col-sm-3 d-flex vertical-middle">
                  <h6 class="mb-0">Choose Advance Payment Option</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  <b-form-radio-group v-model="advance_payment" :options="advance_options"
                    class="d-flex gap-3 justify-content-start"></b-form-radio-group>
                </div>
              </div>
              <!-- <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-9 text-secondary">
                <b-button @click="saveChanges" variant="primary"
                  >Save Changes</b-button
                >
              </div>
            </div> -->
            </b-card-body>
          </b-card>
        </b-col>
        <BookingTable />
      </b-row>
    </b-container>
  </div>
</template>
<script>
import BookingTable from "@/components/BookingTable.vue";
import axios from "axios";
import { BASE_URL } from "../../config";
export default {
  components: {
    BookingTable,
  },
  data() {
    return {
      name: "",
      email: "",
      contact_number: "",
      is_featured: "",
      role_type: "",
      created_at: "",
      editingProfile: false,
      loading: false,
      avaiable_options: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ],
      options: [
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" },
      ],
      advance_options: [
        { text: "33%", value: "partial" },
        { text: "50%", value: "half" },
        { text: "100%", value: "full" },
      ],
    };
  },
  mounted() {
    this.fetchCustomerDetails();
  },
  methods: {
    editProfile() {
      if (this.$store.state.user.role_type === "admin") {
        this.$showErrorMessageToast("Admin profile cannot be edited.");
      }
      else {
        this.editingProfile = true;
      }
    },
    cancelChanges() {
      this.editingProfile = false;
    },
    saveChanges() {
      this.loading = true;

      const customerId = this.$store.state.user.id;

      // Construct the query parameters
      const queryParams = new URLSearchParams({
        name: this.name,
        email: this.email,
        contact_number: this.contact_number,
        club_name: this.club_name,
        is_featured: this.is_featured,
        is_available: this.is_available,
        advance_payment: this.advance_payment,
      });

      axios
        .put(`${BASE_URL}/customer/${customerId}?${queryParams}`, null, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          this.$showSuccessMessageToast(response.data.message);
          this.$store.commit("setUser", response.data.data);
          localStorage.setItem("user", JSON.stringify(response.data.data));
      
          this.editingProfile = false;
        })
        .catch((error) => {
          this.$showErrorMessageToast(error.response.data.error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCustomerDetails() {
      this.loading = true;
      const customerId = this.$store.state.user.id;
      axios
        .get(`${BASE_URL}/customer/${customerId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          this.name = response.data.data.name;
          this.club_name = response.data.data.club_name;
          this.email = response.data.data.email;
          this.contact_number = response.data.data.contact_number;
          this.created_at = response.data.data.created_at;
          this.is_featured = response.data.data.is_feature;
          this.role_type = response.data.data.role_type;
          this.is_available = response.data.data.is_available;
          this.advance_payment = response.data.data.advance_payment;
        })
        .catch((error) => {
          this.$showErrorMessageToast(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
/* Scoped styles only apply to this component */

.card-shadow {
  box-shadow: 0 0 5pt 0.5pt #000000;
}

.text-secondary {
  color: #6c757d !important;
}

.color-bg {
  background: rgba(64, 64, 65, 1);
  background: linear-gradient(180deg, rgba(64, 64, 65, 1), rgba(0, 133, 62, 1));
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 10px inset; */
  box-shadow: 0 0 5pt 0.5pt #000000;

}

.vertical-middle {
  align-items: center;
  justify-content: start;
}

.card-text h4 {
  color: white;
  margin: 0px;
}

.card-text p {
  color: black;
  font-weight: 500;
}

.edit-btn {
  background-color: #404041 !important;
  border: 1px solid #404041 !important;
  color: white !important;
}

.edit-btn:hover {
  background-color: #535354 !important;
  border: 1px solid #535354 !important;
  color: white;
}

.save-btn {
  background-color: #404041 !important;
  border: 1px solid #404041 !important;
  color: white !important;
}

.save-btn:hover {
  background-color: #535354 !important;
  border: 1px solid #535354 !important;
  color: white !important;
}
</style>

<template>
  <div>
    <WebLoader v-if="loading" />
    <b-container id="mainContainer" align-v="center">
      <CustomBg />
      <b-row align-v="center" align-h="center">
        <b-col md="6">
          <div>
            <b-card id="cardLogin" class="scale-in-bl my-3">
              <b-form
                @submit="onSubmit"
                @reset="onReset"
                v-if="show"
                id="formLogin"
              >
                <svg
                  viewBox="0 0 200 200"
                  xmlns="http://www.w3.org/2000/svg"
                  class="blob"
                >
                  <path
                    fill="#00853e"
                    d="M66.2,-49.1C81.5,-33.5,86.8,-6.2,79.8,15.6C72.7,37.5,53.3,53.9,30.9,65.1C8.5,76.2,-16.9,81.9,-36.2,73.3C-55.5,64.7,-68.8,41.7,-70.8,19.9C-72.8,-2,-63.6,-22.7,-49.9,-37.8C-36.2,-53,-18.1,-62.7,3.6,-65.6C25.4,-68.5,50.8,-64.6,66.2,-49.1Z"
                    transform="translate(100 100)"
                  />
                </svg>

                <h1 class="text-center mb-3">Retrieve account</h1>
                <h2 class="text-center mb-4">
                  Enter your account email and leave the rest to us
                </h2>

                <b-form-group id="input-group-1" label-for="email">
                  <b-form-input
                    id="email"
                    class="input"
                    v-model="form.email"
                    type="email"
                    placeholder="E-mail"
                    required
                  ></b-form-input>
                </b-form-group>

                <div
                  
                  class="d-flex justify-content-center mt-3"
                >
                  <a @click="onSubmit" href="#" class="recover-arrow-btn">
                    <img
                      class="arrow-img"
                      src="../assets/images/arrow.png"
                      alt=""
                      srcset=""
                    />
                  </a>
                </div>

                <div
                  class="d-flex justify-content-center flex-wrap mt-4 register g-2"
                >
                  <span class="mr-2"
                    >Have you already recovered your account?
                  </span>
                  <a @click="$router.go(-1)" class="loginAccount"> To Login</a>
                </div>
              </b-form>
            </b-card>
          </div>
        </b-col>
        <b-col md="6">
          <img
            src="../assets/images/business-coffee.png"
            fluid
            class="businessImg scale-in-bottom"
            center
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CustomBg from "../components/CustomBg";
import axios from "axios";
import { BASE_URL } from "../../config";

export default {
  components: {
    CustomBg,
  },
  data() {
    return {
      loading: false,
      form: {
        email: "",
      },
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.loading = true;
      // Send POST request to the forgot password API
      axios
        .post(`${BASE_URL}/forgot-password`, this.form)
        .then((response) => {
          this.$showSuccessMessageToast(response.data.data.message);
          setTimeout(() => {
            this.$router.push("/login");
          }, 1000);

        })
        .catch((error) => {
          this.$showErrorMessageToast(error.response.data.error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onReset(event) {
      event.preventDefault();
      // Reset form data
      this.form.email = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/animations.scss";
@import "../assets/scss/variables.scss";
@import "../assets/scss/fonts.scss";

#mainContainer {
  width: 100%;
  height: auto;
  position: relative;
}

.g-2 {
  gap: 0.2rem;
}

body {
  font-family: $Poppins !important;
}

.arrow-img {
  height: 3rem;
}

#cardLogin {
  border-radius: 15px;
  box-shadow: 0px 0px 10px $gray;

  #formLogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;

    h1 {
      color: $primary;
      font-weight: 700;
      font-family: $Poppins;
    }

    h2 {
      font-size: 1.2rem;
      color: $lightBlack;
      font-weight: 600;
      font-family: $Poppins;
    }

    .other-account {
      color: $gray;
    }

    .icons {
      width: 30px;
      cursor: pointer;
    }

    .input {
      border-radius: 10px;
    }

    .remember {
      color: $lightBlack;
    }

    .blob {
      position: absolute;
      top: -15%;
      left: -12%;
      width: 150px;
      opacity: 0.2;
    }
  }

  /* .logoLogin {
    margin: 0 auto;
    width: 100px;
  } */

  .forgotPassword {
    color: $gray;
    text-decoration: none;

    &:hover {
      color: $primary;
      transition: 0.5s ease-in-out;
    }
  }

  /* .btn-login {
    width: 200px;
    background-color: $primary;
    border: none;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 10px;

    &:hover {
      background-color: $secondary;
      transition: 0.5s ease-in-out;
    }
  } */

  .recover-arrow-btn {
    // width: 100px;
    border-radius: 15px;
    background: $primary;
    // color: #fff;
    // font-size: 60px;
    // padding: 5px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background: $secondary;
      transition: 0.5s ease-in-out;
      box-shadow: none;
    }
  }

  .register {
    span {
      color: $lightBlack;
    }

    .loginAccount {
      color: $primary;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
</style>

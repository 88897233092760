<template>
  <b-container>

    <h2 class="text-center mt-5 grn-color sec-heading">
      PEOPLE BEHIND THE MAGIC
    </h2>

    <!-- People Cards -->
    <b-row class="justify-content-center mt-3">
      <b-col class="d-flex direction-column" lg="4" md="6" v-for="(person, index) in team" :key="index">
        <img
          :src="person.image"
          alt="Team Member"
          thumbnail
          fluid
          rounded-circle
          mb-3
          width="300"
        />
        <h4>{{ person.name }}</h4>
        <p>{{ person.designation }}</p>
      </b-col>
    </b-row>

    <div class=" mission-container my-5">
      <h2 class="first-heading text-center">OUR MISSION</h2>
      <p>
        <strong>FieldFellas</strong> is a unique and first venture in Pakistan. Our platform is
        about booking indoor sports turfs. We provide customers with different
        clubs to book their turfs. Recently indoor sports have emerged in
        Pakistan and there is a huge gap in the market for bookings of these
        clubs. We provide the best solution for booking these, so that customers
        don't have to search for different clubs in Lahore. Our mission is to
        provide high-quality services and products to our customers while
        fostering innovation and sustainability.
      </p>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      team: [
        {
          name: "Arbaaz Akram",
          image: require(`@/assets/latest-ceo.png`),
          designation: "Founder/CEO",
        },
        // {
        //   name: "Abdulrab Yasir",
        //   image: require(`@/assets/p3.png`),
        //   designation: "Co-Founder",
        // },
        // {
        //   name: "Michael Johnson",
        //   image: require(`@/assets/p3.png`),
        //   designation: "Designer",
        // },
      ],
    };
  },
};
</script>

<style>
.first-heading {
  color: #404041;
  font-size: 3rem;
  font-weight: 700;
}

.grn-color {
  color: #00853e;
  font-size: 3rem;
  font-weight: 700;
}

.mission-container {
  background: #f5f5f5;
  padding: 2rem;
  border-radius: 10px;
  /* box-shadow: 0px 4px 
  2px 0px #9999; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 10px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.direction-column {
  flex-direction: column;
  align-items: center;
}
</style>

<template>
    <div @mousemove="handleMouseMove" @mouseleave="resetParallax">
        <h1 class="slogan-text" :style="parallaxStyle">
            FIELD FELLAS
        </h1>
    </div>
</template>
<script>
export default {
    data() {
        return {
            mouseX: 0,
            mouseY: 0,
            parallaxX: 0,
            parallaxY: 0,
        };
    },
    computed: {
        parallaxStyle() {
            // Apply a transform based on the calculated parallax values
            return {
                transform: `translate(${this.parallaxX}px, ${this.parallaxY}px)`,
                transition: 'transform 0.1s ease-out',
            };
        },
    },
    methods: {
        handleMouseMove(event) {
            // Calculate the parallax offset relative to the container
            const rect = event.currentTarget.getBoundingClientRect();
            const offsetX = event.clientX - rect.left;
            const offsetY = event.clientY - rect.top;

            const centerX = rect.width / 2;
            const centerY = rect.height / 2;

            // Adjust these values to control the intensity of the effect
            const intensity = 20;
            this.parallaxX = ((offsetX - centerX) / centerX) * intensity;
            this.parallaxY = ((offsetY - centerY) / centerY) * intensity;
        },
        resetParallax() {
            // Reset the parallax effect when the mouse leaves the container
            this.parallaxX = 0;
            this.parallaxY = 0;
        },
    },
};
</script>

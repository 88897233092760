<template>
  <div class="homepage">
    <div v-if="!isServiceProvider && !isAdmin" class="search-bar position-relative flex-column">
      <b-input-group class="search-align">
        <b-form-input class="search-input" placeholder="Search The Turf" v-model="searchQuery"
          @input="filterClubs"></b-form-input>
        <b-input-group-append>
          <!-- <b-button class="search-button" @click="search">
            <i class="fas fa-search"></i>
          </b-button> -->
        </b-input-group-append>
      </b-input-group>

      <!-- List of clubs based on search -->
      <b-list-group v-if="filteredClubs.length > 0" class="mt-1 list-option position-absolute">
        <b-list-group-item v-for="(club, index) in filteredClubs" :key="index" @click="goToClub(club)"
          class="club-item">
          {{ club.clubName }} - {{ club.area }}
        </b-list-group-item>
      </b-list-group>
    </div>


    <div class="content">
      <h1>#1 TURF SPORTS COMMUNITY IN PAKISTAN</h1>
      <p>
        Field Fellas is the #1 platform for booking turf and sports facilities
        in Pakistan. It's convenient, reliable, and hassle-free reservations at
        your fingertips.
      </p>
      <b-button v-if="!isServiceProvider && !isAdmin" class="book-btn" @click="routeToClub">Book Your Turf</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: "",
      filteredClubs: [], // Clubs filtered by search term
    };
  },
  computed: {
    clubs() {
      return this.$store.getters.vendors.flatMap(vendor =>
        vendor.turf_details.map(turf => ({
          ...turf,
          area: vendor.title, // Add area name to each club
        }))
      );
    },
    isServiceProvider() {
      return this.$store.state.user && this.$store.state.user.role_type === 'service_provider'
    },
    isAdmin(){
      return this.$store.state.user && this.$store.state.user.role_type === 'admin'

    }
  },
  methods: {
    filterClubs() {
      // Filter clubs based on the search term
      if (this.searchQuery) {
        this.filteredClubs = this.clubs.filter(club =>
          club.clubName.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      } else {
        this.filteredClubs = [];
      }
    },
    goToClub(club) {
      const vendor = this.$store.getters.vendors.find(vendor =>
        vendor.turf_details.some(turf => turf.clubId === club.clubId)
      );
      if (vendor) {
        // Set the active tab and navigate to the club's tab
        this.$router.push({
          name: "club",
          params: { area_id: vendor.id, club_id: club.clubId }
        });
      }
    },
    search() {
      // alert("Search button clicked");
      this.filterClubs(this.searchQuery);
    },
    routeToClub() {
      this.$router.push({ name: "club" });
    },
  },
  created() {
    this.$store.dispatch("fetchVendors");
  },
};
</script>


<style scoped>
.homepage {
  background: url("../assets/home-bg.png") no-repeat bottom center;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: slideInFromTop 0.8s ease-out;
  /* Apply the animation directly to the component */
}

/* Define the animation for the transition */
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    /* Start off-screen to the left */
  }

  100% {
    transform: translateY(0);
    /* Slide in to the final position */
  }
}

.search-bar {
  /* width: 30%; */
  padding: 10px 0;
  display: flex;
  justify-content: center;
  animation: slideInFromLeft 0.8s ease-out;
  /* Apply the animation directly to the component */
}

.search-align {
  align-items: center;
}

.search-input {
  background-color: rgba(255, 255, 255, 0.8);
  /* Transparent background */
  color: #404041;
  /* Text color */
  box-shadow: 0 0 5pt 0.5pt #000000;
  border-radius: 15px !important;
  border-color: transparent !important;
  transition: 1s;
}

.search-input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
  background-color: rgba(255, 255, 255, 0.8);
  /* Transparent background */
}

.search-input::placeholder {
  /* Style the placeholder text */
  color: #404041;
}

.search-button {
  padding-inline: 1rem;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  background-color: rgba(0,
      133,
      62,
      0.8);
  /* Adjust the alpha value for transparency */
  border: transparent;
}

.search-button:hover {
  background-color: rgba(0,
      133,
      62,
      0.8);
}

.content {
  text-align: center;
  color: #000;
  margin-top: 20px;
  animation: slideInFromLeft 0.8s ease-out;
  /* Apply the animation directly to the component */
}

.content p {
  color: #ffffff;
  /* transparent background */
  /* text-shadow: 8px 0px 10px rgba(66, 68, 90, 1); */
  /* text-shadow:  -2px 3px 2px #0a0e27; */
  /* background-color: rgba(0, 0, 0, 0.3); */
  padding: 1rem;
  margin-inline: 2rem;
  border-radius: 0.5rem;
}

/* Define the animation for the transition */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    /* Start off-screen to the left */
  }

  100% {
    transform: translateX(0);
    /* Slide in to the final position */
  }
}

.list-option {
  top: 3rem;
  z-index: 1;
  cursor: pointer;
  white-space: nowrap;
}

.club-item:hover {
  background-color: #f8f9fa;
  color: #00853e;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  font-style: italic;
  padding-inline: 2rem;
}

p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  font-weight: 400;
  padding-inline: 2rem;
}

.book-btn {
  background-color: rgba(0,
      133,
      62,
      0.8);
  /* Adjust the alpha value for transparency */
  color: #fff;
  border-radius: 5px;
  /* box-shadow: 0px 2px 4px 0px #ffff; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  transition: transform 0.2s ease-out;
  /* Add a smooth transition for zoom effect */
}

.book-btn:hover {
  background-color: rgba(0,
      133,
      62,
      0.8);
  /* Adjust the alpha value for transparency */
  transform: scale(0.9);
  /* Zoom in effect on hover */
}

@media (max-width: 479px) {
  h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
    font-style: italic;
    padding-inline: 1rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 30px;
    font-weight: 400;
    padding-inline: 1rem;
  }
}
</style>
